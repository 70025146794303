.input {
  position: relative;

  &__start-content {
    position: absolute;
    left: 1rem;
  }
}

.hot-form__input {
  &:focus {
    background: var(--white);
    border: 0.0625rem solid var(--gray-300);
  }
}

.hint-element {
  position: absolute !important;
  height: 0;

  .placeholder-text {
    margin-right: 1rem;
    position: relative;
    z-index: -14;
  }

  .hint-container {
    margin-left: 1.15rem;
    position: relative;
    top: 0.55rem;
    cursor: pointer;
    z-index: 3;
  }
}
.wrapper {
  position: relative;
}
.input-icon {
  position: absolute;
  left: 90%;
  top: calc(50% - 0.5em);
  color: #32363b;
}
