@import '../../global/styles/breakpoints.scss';

.tooltip {
  position: relative;

  .tooltiptext-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltiptext {
    visibility: hidden;
    width: 200px;
    height: 34px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 99999;
    bottom: 120%;
    font-size: 12px;
    @media (max-width: $mobile-max) {
      bottom: 110%;
    }
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltiptext-bn {
    background-color: #fff;
    color: black;
  }

  .tooltiptext-bn::after {
    border-color: #fff transparent transparent transparent;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}
