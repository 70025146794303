@import '../../global/styles/breakpoints.scss';

@mixin title_global {
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  display: inline-block;
  line-height: 20px;
}
.title {
  margin-right: 8px;
}
.title_a {
  background-color: #7c5ee2;
  @include title_global;
}
.title_b {
  background-color: #f0f4ff !important;
  color: #355cc0 !important;
  @include title_global;
}
.title_c {
  background-color: #009d43;
  @include title_global;
}
.title_container {
  border: 1px solid #f0f4ff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 16px;

  @media (max-width: $mobile-max) {
    margin-bottom: 0;
  }
}
.title_container_finish {
  display: flex;
  margin-bottom: 17px;
}

.subtitle {
  color: var(--black);
  font-family: 'Nunito Sans';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;

  &__PL {
    color: #355cc0;
  }

  &__BN {
    color: white;
  }

  &__small {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.counter_container {
  display: flex;
  font-size: 24px;
  color: #282c2f;
  margin-top: 4px;

  @media (max-width: $mobile-max) {
    color: #282c2f;
  }

  .day {
    padding-right: 28px;
  }

  span {
    padding-left: 8px;
  }
}

.counter_container_a {
  font-size: 14px;

  span {
    padding-left: 4px;
  }
}

.form {
  @media (max-width: $desktop-min) {
    padding: 0 16px;
  }
}
.footer {
  .btn-buy {
    background-color: var(--green);
    font-size: 16px;
    min-width: 100%;
    margin-top: 0.5rem;
  }
}

.btn-buy {
  font-style: normal;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  font-size: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}

@media (max-width: $desktop-min) {
  .footer {
    &.footer {
      display: flex;
      flex-direction: column-reverse;
      z-index: 1055;
      position: fixed;
      overflow: hidden;
      min-width: 320px;
      max-height: 500px;
      min-height: 70px;
      width: 100%;
      height: 150px;
      bottom: 0;
      left: 50%;
      padding: 12px 16px;
      transform: translateX(-50%);
      background-color: transparent;
      text-align: center;
    }
    &.footer:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.4);
      background: white;
    }

    .btn-buy {
      margin-top: 10px;
    }
  }

  .section {
    &__form {
      padding: 1rem 1.25rem 0;
    }

    &__launch {
      margin-top: 2rem;
    }
  }
}

.input_invalid {
  border: 1px solid var(--red) !important;
}

.input_valid {
  border: 1px solid var(--green) !important;
}

.tooltip-msg {
  z-index: 1056;
  position: fixed;
  min-width: 320px;
  bottom: 60px;
  left: 50%;
  padding: 12px 16px;
  transform: translateX(-50%);
  background-color: transparent;
}
.checkbox_label {
  font-size: 10px;
}
.format-input {
  margin-bottom: 1.25rem !important;
}
.back-block {
  display: flex;
  align-items: center;
  border: 0;
  padding-left: 0;
  background-color: transparent;
  border-color: transparent;
  color: var(--gray-500);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.back-block {
  cursor: pointer;
}
.back-text {
  margin-top: 31px;
  margin-right: 5px;
  color: #9ea4ac;
  font-size: 12px;
}
.privacity-button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}
.popover-size {
  background-color: red;
}
