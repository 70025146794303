.label {
  margin-bottom: 0.5rem;

  &__required {
    margin-left: 0.25rem;
    font-size: var(--text-2);
    color: var(--red);

    &__small {
      margin-left: 0.125rem;
      font-size: var(--text-1);
    }
  }
}

.label-checked {
  color: var(--green-light);
  margin-left: 0.4rem;
}
.tooltip-icon {
  margin-left: 0.4rem;
}
