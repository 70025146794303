.helper-text {
  font-size: var(--text-1);
  line-height: 150%;
  margin-top: var(--spacer-1);
  width: 100%;
  color: var(--gray-500);
}

.d-block {
  display: block;
}
